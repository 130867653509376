import React from "react"
import { graphql } from "gatsby"
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import HeroHeader from "../components/heroheader"


class PhotosPage extends React.Component {
  render() {

    const instas = get(this, 'props.data.allInstaNode.edges');  

    return (
      <Layout>
        <SEO title="Tom Howard's Photos" />

        <HeroHeader title="Photos" summary="Some photos I've taken"/>

        <Grid>

          {instas.map(({ node }) => {
              return (

               <Photo key={node.id}> 
                <Img fluid={node.localFile.childImageSharp.fluid} />
               </Photo>
                  
              )
                
          })}

        </Grid>

      </Layout>
    )
  }
}

export default PhotosPage

export const pagePhotosQuery = graphql`
  query PhotosPageQuery {
    site {
      siteMetadata {
        title
      }
    }

     allInstaNode(sort: { fields: timestamp, order: DESC }, limit: 28) {
      edges {
        node {
          caption
          id
          timestamp
          likes
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600, maxHeight: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

const Grid = styled.div`
  max-width: 1200px;
  margin: 40px 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); 

  @media(min-width: 768px) {
    margin: 40px auto;
  }
`

const Photo = styled.div`
  padding: 5px;
`