import React from "react"
import styled from "styled-components"
import config from "../../config"

const HeroHeader = ({ title, summary }) => {

  return (
    <Container>
      <h1>{title}</h1>
      <p>{summary}</p>
    </Container>
  );

}

export default HeroHeader


const Container = styled.div`
  background: ${config.secondaryColor};
  padding: 80px 40px;
  color: #fff;

  @media(min-width: 768px) {
    padding: 80px;
  }

  h1 {
    margin-bottom: 10px;
  }
`


